import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import config from "../../config"

const Intro = ({ siteTitle }) => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "tom-howard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div className="intro-image">
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </div>

      <div className="intro-text">
        <span>Welcome, my name is</span>
        <h1>Tom Howard</h1>
        <p>I'm a Software engineer living in Brighton, UK with my wife & two children. I have experience in both frontend and backend development from working on
        brochure websites to web applications and integrations. You can checkout some of my latest <a href="/projects">projects</a>.</p>

        <p>I like to keep up to date with the latest tech news, go out and about on photography trips with friends and family, traveling near and far and I'm also a lover of a good cuppa coffee.</p>
      </div>
    </Container>
  );

}



export default Intro


const Container = styled.div`
  background: ${config.secondaryColor};
  padding: 80px;
  display: flex;
  flex-direction: column;

  @media(min-width: 600px) {
    flex-direction: row;
  }

  .intro-image {
    width: 100%;

    @media(min-width: 600px) {
      width: 50%;
    }

    @media(min-width: 768px) {
      width: 20%;
    }
  }

  .intro-text {
    color: #fff;
    margin: 0;
    padding-top: 30px;
    text-align: center;
    width: 100%;

    @media(min-width: 600px) {
      margin-left: 40px;
      padding: 0;
      text-align: left;
      width: 80%;
    }


    a {
      color: #2a8fdf;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`


