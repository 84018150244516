import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'


export default ({ article }) => (

  <Container className={`article-teaser`}>
    <Link to={`${article.path.alias}`}>
     <div className="image-wrapper">
        <img src={article.relationships.field_image.localFile.childImageSharp.fixed.src} 
        alt={article.relationships.field_image.alt} />
      </div>  
      <div className="teaser-content">
        <Heading>{article.title}</Heading>
        <Created>{article.created}</Created>
        <p>{article.body.summary}</p>
      </div>
    </Link>
  </Container>
)
 
const Container = styled.div`
 background: #F7F8FA;

 a {
   text-decoration: none;
 }

 .teaser-content {
    padding: 30px;

    p {
      color: black;
    }
 }

 .image-wrapper {
    overflow: hidden;

    img {
      display: block;
      height: auto;
      transform: scale(1);
      transition: transform .2s; 
    }
 }

 &:hover {
   img {
        transform: scale(1.2);
      }

   h2 {
      color: #157bbf;
    }
 }
`

const Heading = styled.h2`
  color: #000;
  margin-bottom: 0;
  font-size: 22px;
`

const Created = styled.small`
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  color: #949393;
`