import React from "react"
import styled from "styled-components"
import config from "../../config"

const SkillsCards = () => {

  return (
    <Container>

      <SkillContainer>
        <Skill>
          <i className="fas fa-laptop-code"></i>
          <h3>Frontend</h3>
           <ul>
            <li>Javascript</li>
            <li>Gatsby</li>
            <li>HTML/CSS/SCSS</li>
           </ul>
         </Skill>

         <Skill>
         <i className="fas fa-terminal"></i>
          <h3>Backend</h3>
           <ul>
            <li>PHP</li>
            <li>Laravel</li>
            <li>MYSQL</li>
           </ul>
         </Skill>

         <Skill>
         <i className="fas fa-code-branch"></i>
          <h3>Other</h3>
           <ul>
            <li>Git</li>
            <li>Drupal</li>
            <li>Unit & Cypress tests</li>
           </ul>
         </Skill> 
      </SkillContainer>
      
    </Container>
  );

}


export default SkillsCards


const Container = styled.div`
  max-width: 1200px;
  margin: 80px auto;
`

const SkillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`
 
const Skill = styled.div`
  text-align: center;  
  background: #F7F8FA;
  padding: 0 80px 50px 80px;
  width: 100%; 
  margin-bottom: 40px;

  @media(min-width: 600px) {
    width: 30%;
  }
  
  i { 
    background: ${config.secondaryColor};
    height: 80px;
    width: 80px;
    text-align: center;
    color: white;
    vertical-align: middle;
    line-height: 2.5em;
    border-radius: 50%;
    font-size: 30px;
    margin-bottom: 15px;
    margin-top: -30px;
  }

  ul {
    margin: 0;
    list-style: none;
  }
`