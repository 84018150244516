import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Intro from "../components/intro"
import SkillsCards from "../components/skills-cards"
import ProjectItem from "../components/projectItem"
import Button from "../components/button"
import ArticleTeaser from "../components/articleteaser"

class IndexPage extends React.Component {
  render() {

    const projects = get(this, 'props.data.allNodeProject.edges');  
    const posts = get(this, 'props.data.allNodeArticle.edges')


    return (
      <Layout>
        <SEO title="Home" />

        <Intro />

        <SkillContainer>
          <h2>My Skills</h2>
          <p>I have over 8 years of commercial experience in developing websites and web applications. 
          <br/> See below an overview of some of my skills.</p>

          <SkillsCards />

        </SkillContainer>

        <RecentProjects>
          <h2>Projects</h2>
          <TeaserList className="project-list">
              {projects.map(({ node }) => {
                return (
                  <TeaserListItem key={node.path.alias}>
                    <ProjectItem item={node} />
                  </TeaserListItem>
                )
              })}
            </TeaserList>

            <Button link="/projects" text="View all" />

        </RecentProjects>


         <RecentArticles>
          <h2 className="title">Latest posts</h2>

          <TeaserList>
              {posts.map(({ node }) => {
                return (
                  <TeaserListItem className="article-item">
                    <ArticleTeaser article={node} />
                  </TeaserListItem>
                )
              }
              )
            }
          </TeaserList>

            <Button link="/blog" text="View more" />
        </RecentArticles>

          
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query FeaturedPortfolioItemsQuery {
    site {
      siteMetadata {
        title
      }
    }

    allNodeProject(sort: {fields: created, order: DESC}, limit: 3) {
      edges {
        node {
          title
          created(formatString: "dddd Do, MMMM YYYY")
          path {
            alias
          }
          field_link {
            uri
          }
          field_summary {
            processed
          }
          relationships {
            field_project_tags {
              name
            }
            field_images {
              localFile {
                publicURL
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fixed(width: 700, height: 500) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            } 
            field_teaser_image {
              localFile {
                publicURL
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fixed(width: 700, height: 500) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            } 
          }
        }
      }
    }

    allNodeArticle(sort: {fields: created, order: DESC}, limit: 3) {
      edges {
        node {
          title
          created(formatString: "dddd Do, MMMM YYYY")
          path {
            alias
          }
          body {
            summary
          }
          relationships {
            field_image {
              localFile {
                publicURL
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fixed(width: 700, height: 500) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
`


const SkillContainer = styled.div`
  padding: 40px 20px 0 20px;

  p,
  h2 {
    text-align: center;
  }
`
const RecentProjects = styled.div`
  background: #F7F8FA;
  padding: 40px;
  text-align: center;

  @media(min-width: 768px) {
    padding: 80px;
  }
`
const RecentArticles = styled.div`
  background: #FFFFFF;
  padding: 40px;

  @media(min-width: 768px) {
    padding: 80px;
  }

  h2.title {
    text-align: center;
  }
`

const TeaserList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 10px;

  .article-item {
    background-color: #F7F8FA;
  }

  &.project-list {
    .article-teaser {
      background-color: #fff;
    }
  }
`
const TeaserListItem = styled.li`
  list-style: none;
  flex: 100%;
  margin: 10px;
  background: #fff;

  h2:last-child {
    margin-bottom: 15px;
  }

  @media(min-width: 575px) {
    flex: 0 0 30%;
  }
`