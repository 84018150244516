import React from 'react'
import get from 'lodash/get'
import { Link } from 'gatsby'
import styled from 'styled-components'
import config from "../../config"

class ProjectItem extends React.Component {
  render() {

    const item = get(this, 'props.item');  
    const tags = get(this, 'props.item.relationships.field_project_tags'); 

 return (
     <Container className={`article-teaser`}>
    <a href={`${item.field_link.uri}`} target="_blank">

      <div className="image-wrapper">
        <img src={item.relationships.field_teaser_image.localFile.childImageSharp.fixed.src} 
        alt={item.relationships.field_teaser_image.alt} />
      </div> 
      <div className="content-wrapper">
        <Heading>{item.title}</Heading>

        <Tags><i class="fas fa-tag"></i>
            {tags.map(( tag ) => {
              return (
                <span>{tag.name}</span>
              )
            })}
        </Tags>

        <div className="summary" dangerouslySetInnerHTML={{ __html: item.field_summary.processed }}></div>

      </div>  
    </a>
  </Container>

  )

  }
}



export default ProjectItem


const Tags = styled.div`
  color: #123d5f;

  .fas {
    font-size: 14px;
    margin-right: 5px;
  }

  span {
    margin-right: 5px;

    &:after {
      content: ',';
    }
  }

  span:last-child {
    &:after {
      content: '';
    }
  }
`
 
const Container = styled.div`
  background: #F7F8FA;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  .content-wrapper {
     margin: 20px;
  }

 .image-wrapper {
    overflow: hidden;

    img {
      display: block;
      height: auto;
      transform: scale(1);
      transition: transform .2s; 
    }
 }

 &:hover {
   img {
        transform: scale(1.2);
      }
 
   h2 {
      color: ${config.secondaryColor};
    }
 }

 .summary {
    margin-top: 10px;
    color: #123d5f;
 }
`

const Heading = styled.h2`
  margin-bottom: 20px;
  color: #272f32;
  font-size: 20px;
`