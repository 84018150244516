import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import config from "../../config"

const Button = ({ link, text, target}) => {

  return (
    <ButtonLink to={`${link}`} target={`${target}`}>{text}</ButtonLink>
  );

}


Button.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  target: PropTypes.string
}



export default Button



const ButtonLink = styled(Link)`
  margin: 20px auto;
  padding: 10px;
  display: block;
  text-align: center;
  background: ${config.secondaryColor};
  max-width: 200px;
  color: #fff;
  text-decoration: none;

  &:hover {
     background: ${config.primaryColor};
  }
`